import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromoDataById } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { getRoute } from 'src/utils/routes';
import { Button } from '../DesignSystem/AtomicComponents';
import { deviceWidth } from 'src/utils/deviceWidth';
import Modal from '../Common/Modal';

const PromoDetailModalOld = ({ promoId, onClose, bannerImage }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { storeData } = useSelector((state: RootState) => ({
    storeData: state.storeReducer.store,
  }));

  const [coupon, setCoupon] = useState(null);

  const requiredLinksData = coupon?.required_entity_chips || [];
  const rewardListData = coupon?.reward_entity_chips || [];

  const [showMoreRequired, setShowMoreRequired] = useState(false);
  const [showMoreReward, setShowMoreReward] = useState(false);

  useEffect(() => {
    getPromoData();
  }, [promoId]);

  function getPromoData() {
    const params = {
      storeId: storeData?.store_id,
      promoId,
    };
    dispatch(
      getPromoDataById(params, (data) => {
        setCoupon(data);
      })
    );
  }

  const handleRequiredChipClick = (url) => {
    router.push(getRoute(url, storeData.store_info.domain));
  };

  const renderRequiredLinks = (requiredLinks) => {
    const totalRequiredLinks = requiredLinks?.length;
    return (
      !!totalRequiredLinks && (
        <div>
          <div className={`${deviceWidth > 768 ? 'f16px' : 'f12px'} mt30px fw6 tw-flex`}>
            {coupon?.requirement_header_text || ''}
          </div>
          <div className="tw-flex tw-flex-wrap tw-text-nowrap">
            {requiredLinks
              .slice(0, showMoreRequired ? totalRequiredLinks : 6)
              .map((linkData, index) => (
                <Button
                  px="20px"
                  py="5px"
                  fontSize="12"
                  label={linkData?.text}
                  backgroundColor="white"
                  fontColor="#474748"
                  borderColor="#DDDDDD"
                  roundness={4}
                  mt="12px"
                  mr="5px"
                  key={linkData.id}
                  hoverEnabled={true}
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleRequiredChipClick(linkData?.url);
                  }}
                ></Button>
              ))}
            {totalRequiredLinks > 6 && (
              <span
                className="tw-flex tw-cursor-pointer tw-items-center tw-text-[13px]/[20px] tw-font-semibold"
                onClick={() => setShowMoreRequired(!showMoreRequired)}
              >
                {showMoreRequired ? 'Less' : 'More'}
              </span>
            )}
          </div>
        </div>
      )
    );
  };

  const renderRewardChips = (rewardChips) => {
    const totalRewardChips = rewardChips?.length;

    return (
      !!totalRewardChips && (
        <div>
          <div
            className={
              deviceWidth > 768 ? 'f16px fw6 tw-mt-[24px]' : 'f12px fw6 tw-mt-[24px]'
            }
          >
            {coupon?.reward_header_text || ''}
          </div>
          <div className="tw-mt-[12px] tw-text-[12px] tw-font-medium">
            {rewardChips
              .slice(0, showMoreReward ? totalRewardChips : 6)
              .map((rewardData) => rewardData?.text || '')
              .join(', ')}
            {totalRewardChips > 6 && (
              <span
                className="tw-inline-flex tw-cursor-pointer tw-items-center tw-font-semibold"
                onClick={() => setShowMoreReward(!showMoreReward)}
              >
                ...{showMoreReward ? 'Less' : 'More'}
              </span>
            )}
          </div>
        </div>
      )
    );
  };

  return (
    coupon && (
      <Modal
        visible={true}
        height={300}
        animation={'fadeIn'}
        onClose={onClose}
        className="couponDetails-overlay"
        customStyles={{
          top: '50%',
          left: '50%',
          bottom: 'initial',
          right: 'initial',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          borderRadius: '15px',
          maxWidth: '450px',
        }}
        customMaskStyles={{
          background: '#000000e0',
        }}
      >
        <section className="topdesign relative br4 br--top h4">
          <img
            src={bannerImage || '/assets/images/bg-ribbon.png'}
            className="h-100 w-100 br4"
            alt="Promo Code background"
            loading="lazy"
          />
        </section>
        <div className="tw-max-h-[420px] tw-overflow-scroll">
          <section className="details  br4 br--bottom bg-white">
            <div className="f3 fw7 mt4 mb3">{coupon?.description}</div>
            <article>
              <span className="ba b--black-20 br2 pa1 f6 b--dashed">
                Use Code: <strong>{coupon?.code}</strong>
              </span>
            </article>
            {coupon?.offer_eligiblities?.map((eligiblity, index) => (
              <p key={index} className="f7 black-100 mt3">
                {eligiblity}
              </p>
            ))}
          </section>
          <div className="tw-flex-col">
            {renderRequiredLinks(requiredLinksData)}
            {renderRewardChips(rewardListData)}
          </div>
        </div>
        <div className="cross-icon absolute w-100 flex flex-column justify-center items-center">
          <p className="white-70 f7 tc flex justify-center items-center">
            <img src="/assets/images/ic_bulb.png" alt="bulb" className="img h1 mr2" /> Use
            this coupon while placing an order
          </p>
          <span className="bg-white-20 h2 w2 br4 flex justify-center items-center pointer">
            <img
              src="/assets/images/cancel_white.png"
              alt="cross"
              className=" img h1"
              onClick={onClose}
            />
          </span>
        </div>
      </Modal>
    )
  );
};

export default PromoDetailModalOld;
