/**
 * Promo List Component
 */
import React, { useEffect, useRef, useState } from 'react';
import { deviceWidth } from '@/utils/deviceWidth';
import { PercentageIcon } from '@/assets/svgExports/PercentageIcon';
import { useDispatch } from 'react-redux';
import { Slider } from '@/components/ReactSlickCarousel';
import { saveCouponsList } from 'src/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import { getCouponList } from 'src/services/store';
import PromoDetailModalOld from './PromoDetailModalOld';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';

const PromoList = (props) => {
  const dispatch = useDispatch();

  const sliderRef = useRef();

  const [activeSlide, setActiveSlide] = useState(0);
  const [couponDetailOverlay, setCouponDetailOverlay] = useState({
    show: false,
    promoId: null,
  });
  const [bannerImage, setbannerImage] = useState('');

  const { storeData, couponsList, isCustomWebsite } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    couponsList: state?.promoReducer?.couponsList,
    isCustomWebsite: state.storeReducer.store?.theme?.custom_theme_flag,
  }));

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  useEffect(() => {
    storeData?.store_id &&
      !couponsList?.length &&
      isCustomWebsite &&
      !isCouponsFeatureLocked &&
      getCouponList(storeData.store_id).then((data) => {
        dispatch(saveCouponsList({ coupons: data }));
      });
  }, [storeData?.store_id, couponsList?.length, isCouponsFeatureLocked]);

  useEffect(() => {
    storeData?.theme?.components?.Body?.forEach((layout) => {
      if (layout.sub_type === 'HeroBanner')
        setbannerImage(
          layout.images
            ?.filter((el) => el.is_desktop === false)
            .sort((a, b) => a.order - b.order)[0]?.image_url
        );
    });
  }, []);

  // do not show old promo slider if not custom website
  if (!isCustomWebsite) {
    return <></>;
  }

  /**
   * Slider base values
   */
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: deviceWidth > 990 ? false : true,
    slidesToShow: deviceWidth > 990 ? 3 : 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: 'dotted_icons',
    arrows: false,
    afterChange: (current) => setActiveSlide(current),
  };

  /**
   * slider prev button
   * click handler function
   */
  const onPrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  /**
   * slider next button
   * click handler function
   */
  const onNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  /**
   * Helper function to toggle
   * the Coupon details overlay
   * @param {number} idx
   */
  const onToggleCouponDetailsOverlay = (promoId) => {
    setCouponDetailOverlay((state) => ({
      show: !state.show,
      promoId: state.show === false ? promoId : null,
    }));
  };

  const _renderCoupon2 = (coupon, idx) => {
    return (
      <div
        key={idx}
        className="coupon-card2 br3 relative flex"
        onClick={() => onToggleCouponDetailsOverlay(coupon?.id)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#fff" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start white h-100 !tw-w-full tw-px-[16px]">
            <h4 className="f5 mt0 mb1">{coupon?.description}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7 ">{coupon?.code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon3 = (coupon, idx) => {
    return (
      <div
        className="coupon-card2 br3 relative flex"
        key={idx}
        onClick={() => onToggleCouponDetailsOverlay(coupon?.id)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#000" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start h-100 black !tw-w-full tw-px-[16px]">
            <h4 className="f5 mt0 mb1">{coupon?.description}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7">{coupon?.code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon4 = (coupon, idx) => {
    return (
      <div
        key={idx}
        className="coupon-card4 br3 relative flex"
        onClick={() => onToggleCouponDetailsOverlay(coupon?.id)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#fff" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start white h-100 !tw-w-full tw-px-[32px]">
            <h4 className="f6 mt0 mb1">{coupon?.description}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7 ">{coupon?.code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };
  const _renderCoupon5 = (coupon, idx) => {
    return (
      <div
        className="coupon-card4 br3 relative flex"
        key={idx}
        onClick={() => onToggleCouponDetailsOverlay(coupon?.id)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon alt="percentage" color="#000" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start black h-100 !tw-w-full tw-px-[32px]">
            <h4 className="f6 mt0 mb1">{coupon?.description}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7">{coupon?.code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img
                alt="promo code background"
                src={bannerImage}
                loading="lazy"
                className=""
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon1 = (coupon, idx) => {
    return (
      <article
        key={idx}
        className="coupon-card1 br3 relative tw-h-full"
        onClick={() => onToggleCouponDetailsOverlay(coupon?.id)}
      >
        <PercentageIcon color="#EA5151" />
        <h4 className="f5 mt0 mb1">{coupon?.description}</h4>
        <p className="flex justify-start items-center f7 mv0 flamingo-color">
          <div>Use Code:</div>
          <div className="f6 ml1 ">{coupon?.code}</div>
        </p>
      </article>
    );
  };

  const _renderCoupons = (coupon, idx, cardNumer) => {
    cardNumer = 2;
    switch (cardNumer) {
      case 2:
        return _renderCoupon2(coupon, idx);
      case 3:
        return _renderCoupon3(coupon, idx);
      case 4:
        return _renderCoupon4(coupon, idx);
      case 5:
        return _renderCoupon5(coupon, idx);
      default:
        return _renderCoupon1(coupon, idx);
    }
  };

  return (
    couponsList?.length > 0 && (
      <div className="promoList bg-white">
        <section className={`header flex justify-between`}>
          <h3 className={`promoList__title fw6 tc center mv0`}>Offers</h3>
        </section>
        <>
          <section className={`header flex justify-between`}>
            {settings.slidesToShow < couponsList.length && deviceWidth > 990 && (
              <div className="action-wrapper flex justify-center items-center">
                <img
                  src="/assets/images/arrowb.png"
                  className={`img h1 mr2 ${activeSlide === 0 && 'o-20'}`}
                  onClick={onPrevClick}
                  alt="prev arrow"
                ></img>
                <img
                  src="/assets/images/arrowf.png"
                  className={`img h1 mr2 ${
                    (activeSlide === couponsList.length - 1 ||
                      activeSlide === couponsList.length - settings.slidesToShow) &&
                    'o-20'
                  }`}
                  onClick={onNextClick}
                  alt="next arrow"
                ></img>
              </div>
            )}
          </section>
          <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
            {couponsList?.map((coupon, idx) => {
              return _renderCoupons(coupon, idx, props.cardNumer || 1);
            })}
          </Slider>
          {deviceWidth < 990 && (
            <section className="flex justify-center items-center mt2">
              {/* <input
               className=""
               value={activeSlide}
               type="range"
               min={0}
               max={couponsList.length - 1}
             /> */}
              <progress
                max={couponsList.length - 1}
                value={activeSlide}
                className="slider-scrollbar bg-grey"
                style={{
                  color: storeData?.theme?.colors?.primary_color || '#ea5151',
                }}
              ></progress>
            </section>
          )}
          {couponDetailOverlay.show && (
            <PromoDetailModalOld
              promoId={couponDetailOverlay.promoId}
              onClose={onToggleCouponDetailsOverlay}
              bannerImage={bannerImage}
            />
          )}
        </>
      </div>
    )
  );
};
export default PromoList;
