export function PercentageIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 14.984 15.292"
      {...props}
    >
      {/* <defs>
          <style>.a{fill:#fff;}</style>
        </defs> */}
      <g transform="translate(0 0)">
        <ellipse
          className="a"
          fill={props.color || '#000000'}
          cx="0.639"
          cy="0.639"
          rx="0.639"
          ry="0.639"
          transform="translate(4.821 5.708)"
        />
        <ellipse
          className="a"
          fill={props.color || '#000000'}
          cx="0.639"
          cy="0.639"
          rx="0.639"
          ry="0.639"
          transform="translate(8.882 8.303)"
        />
        <path
          fill={props.color}
          className="a"
          d="M19.711,8.49l-.232-.316a1,1,0,0,1-.009-1.163l.226-.32a1,1,0,0,0-.345-1.454l-.345-.185a.993.993,0,0,1-.511-1.043l.066-.385a1,1,0,0,0-.94-1.159l-.392-.016a1,1,0,0,1-.915-.718l-.11-.376A.993.993,0,0,0,14.854.719l-.36.154A.993.993,0,0,1,13.359.622L13.1.331a.993.993,0,0,0-1.492.013l-.257.295A1,1,0,0,1,10.22.9L9.856.754a1,1,0,0,0-1.341.658l-.1.379a.989.989,0,0,1-.9.73l-.392.022A1,1,0,0,0,6.2,3.718l.072.385a.993.993,0,0,1-.5,1.05l-.342.191A.994.994,0,0,0,5.112,6.8l.232.316A1,1,0,0,1,5.353,8.28l-.226.32a1,1,0,0,0,.345,1.454l.345.185a.993.993,0,0,1,.511,1.043l-.066.385a1,1,0,0,0,.94,1.159l.392.016a1,1,0,0,1,.915.718l.11.376a.993.993,0,0,0,1.351.636l.36-.154a.993.993,0,0,1,1.134.251l.26.291a.993.993,0,0,0,1.492-.013l.257-.295a1,1,0,0,1,1.131-.266l.363.15a1,1,0,0,0,1.341-.658l.1-.379a.989.989,0,0,1,.9-.73l.392-.022a1,1,0,0,0,.921-1.175l-.072-.385a.993.993,0,0,1,.5-1.05l.342-.191A.993.993,0,0,0,19.711,8.49ZM8.835,6.347a1.542,1.542,0,1,1,1.542,1.542A1.541,1.541,0,0,1,8.835,6.347Zm1.839,3.976a.377.377,0,0,1-.53.05h0a.377.377,0,0,1-.05-.53l4.045-4.873a.376.376,0,1,1,.58.479Zm3.766.16a1.542,1.542,0,1,1,1.542-1.542A1.541,1.541,0,0,1,14.44,10.483Z"
          transform="translate(-4.919 0)"
        />
      </g>
    </svg>
  );
}
